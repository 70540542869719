@import bna/legacy
@import bna/header
@import bna/footer
@import title_pages/newspaper_titles
@import misc

body
  font-size: 13px
  font-family: "Avenir next",avenirnext,avenir,Calibri,"Helvetica neue",Helvetica-neue,Helvetica,Arial,sans-serif
  font-feature-settings: "liga" 0
  line-height: 18px
  background: #fff
  color: #58595b
  -moz-font-feature-settings: "liga" 0
  margin: 0
  padding: 0
  border: 0
  a, .capital
    color: #507097
  legend
    margin: 0
    padding: 0
    boder: 0

input, select, textarea
  font-family: "Lucida Sans","Lucida Grande","Lucida Sans Unicode",Helvetica,Arial,sans-serif
  color: #3d5055

input[type="submit"], input[type="button"], .popUpButton, a.buttonclear
    color: #fff
    padding: 6px 10px
    font-weight: bold
    background-color: #242048
    overflow: visible
    border-radius: 20px
    border: 0
    width: auto
    cursor: pointer

.breadcrumb
  background-color: unset

.section
  background: transparent
  max-width: 1000px
  margin: auto
  min-width: 300px

.tick text, .label
  font-size: 80%
  fill: #807869
  opacity: 0.9
  font-weight: unset

.spinner
  position: relative
  width: 40px
  height: 40px
  background-color: #507097
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out
  animation: sk-rotateplane 1.2s infinite ease-in-out

@-webkit-keyframes sk-rotateplane
  0%
    -webkit-transform: perspective(120px)
  50%
    -webkit-transform: perspective(120px) rotateY(180deg)
  100%
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)

@keyframes sk-rotateplane
  0%
    transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  50%
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  100%
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
